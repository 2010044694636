import React from "react";
import { graphql } from "gatsby";
import ProductsListWithFilters from "../../containers/shop/ProductsListWithFilters";
import Page from "../../components/page";
import Seo from "../../components/seo";

const BrandPage = ({ data }: { data: Queries.BrandPageQueryDataQuery }) => {
  const brand = data.datoCmsBrand;

  return (
    <>
      {/* SEO & E-commerce data */}
      {brand.seoMetaTags && (
        <Seo seoMetaTags={brand.seoMetaTags}>
          <title>{`${brand.name} | AIRGRAFT 2`}</title>
        </Seo>
      )}
      <Page container="fluid">
        <ProductsListWithFilters
          products={data.allDatoCmsProduct.nodes}
          defaultTitle={brand.name}
        />
      </Page>
    </>
  );
};

export const query = graphql`
  query BrandPageQueryData($locale: String!, $slug: String!) {
    datoCmsBrand(slug: { eq: $slug }) {
      slug
      name
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allDatoCmsProduct(
      filter: { locale: { eq: $locale }, disablePlp: { ne: true } }
      sort: { fields: position }
    ) {
      nodes {
        ...DatoCmsProductItemFragment
      }
    }
  }
`;

export default BrandPage;
